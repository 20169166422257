import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import logo from '../Assets/Images/move_to_france.svg';



function Footer() {

  return (
        <footer className='primary-background-color '>
            <Container className='bg-white '>
                
                <Row className='' >
                    
                    <Col xs={12} md={3} className="">
                    <img
                        alt="Move to France"
                        src={ logo }
                        
                        height="45"
                        className="d-inline-block align-top" 
                    />
                    <br />
                    <a class="blog-header-logo text-body-emphasis text-decoration-none" href="#">Move to France</a>
                    </Col>
                    <Col xs={12} md={3} className="justify-content-end align-items-center">
                        
                            <p>Terms and Conditions</p>
                    
                    </Col>
                    <Col xs={12} md={6} className=" d-flex justify-content-end align-items-center">
                        
                    <i className='fa fa-brands fa-instagram'></i>
          <i className='fa fa-brands fa-facebook'></i>
          <i className='fa fa-brands fa-linked'></i>
          <i className='fa fa-brands fa-x'></i>
                        
                        
                        
                    </Col>
                
                </Row>
            </Container>
        </footer>

  );
}

export default Footer;